const dialogMixin = {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    dialog(newValue) {
      if (newValue) {
        this.onDialogOpen();
      }
    },
  },

  created() {
    this.onDialogOpen();
  },

  methods: {
    // Override this inside dialog component. Treat it as the created() hook.
    // The reason why you can't use created() is because once dialog is opened and closed,
    // created() won't be called if it's opened again.
    onDialogOpen() {},
  },
};

export default dialogMixin;
