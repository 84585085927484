/* eslint-disable import/prefer-default-export */
export function mapErrorsToInputs(errors) {
  const mappedErrors = {};
  if (errors.response && errors.response.status === 422) {
    Object.keys(errors.response.data.errors).forEach((key) => {
      mappedErrors[key] = errors.response.data.errors[key];
    });
  }
  return mappedErrors;
}
